<template>
  <div class="app-container">
    <ExtendedCrudTable
      ref="table"
      :entity="entity"
      :entity-store="entityStore"
      :columns="columns"
      :generate-summary="generateSummary"
      :definition="definition"
      :caption="caption"
      :disabled="!hasReadPermission"
      parent-id-filter-field="dossierID"
      :create-item-from-form-data="createItemFromFormData"
      :create-form-data-from-item="createFormDataFromItem"
      :required="required"
      :open-in-fullscreen="openInFullscreen"
    />
  </div>
</template>
<script>
import crudIndexMixin from '@/mixins/crud-index';
import EmbeddedCrudTable from '@/components/crud/EmbeddedCrudTable';
import formMixin from '@/pages/dossiers/dossieractions/form-mixin';
import { hasReadPermissionForCode } from '@/utils/permission';
import { getFormDefinition, createItemFromFormData, createFormDataFromItem } from './form';

const ExtendedCrudTable = {
  name: 'ExtendedCrudTable',
  extends: EmbeddedCrudTable,
  methods: {
    showCreate() {
      if (this.disabled) {
        return false;
      }
      this.$router.push({ name: 'CreateDossierAction', params: { dossierID: this.$route.params.id } });
      this.$emit('create');
    },
    showEdit(id) {
      if (this.disabled) {
        return false;
      }
      this.$router.push({ name: 'EditDossierAction', params: { id: id, dossierID: this.$route.params.id } });
      this.$emit('edit');
    }
  }
};
export default {
  components: {
    ExtendedCrudTable
  },
  mixins: [crudIndexMixin, formMixin],
  props: {
    caption: String,
    openInFullscreen: {
      default: false,
      type: Boolean
    },
    required: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      columns: [
        { field: 'actingRoleName', label: 'dossierAction.actingRoleName' },
        { field: 'loanID', label: 'loan.actNumber' },
        { field: 'actionTypeName', label: 'dossierAction.actionTypeName', width: 250 },
        { field: 'comment', label: 'dossierAction.comment' },
        { field: 'actionStatusName', label: 'dossierAction.actionStatusName' },
        { field: 'creationDate', label: 'dossierAction.creationDate', type: 'date' },
        { field: 'alertDate', label: 'dossierAction.alertDate', type: 'date' }
      ],
      isDisabledDelete: e => {
        console.debug(e);
        return true;
      }
    };
  },
  computed: {
    hasReadPermission() {
      return hasReadPermissionForCode(this.entity, this.$store.getters.permissions);
    },
    definition() {
      return getFormDefinition(this);
    },
    loading() {
      return this.$store.getters[this.entityStore + '/isLoading'];
    },
    dossierID() {
      return parseInt(this.$route.params.id);
    }
  },
  methods: {
    getDependencies(data) {
      // return [{ entity: 'actionType' }, { entity: 'actionStatus' }, { entity: 'role' }, { entity: 'loan' }];
      return [{ entity: 'actionType' }, { entity: 'actionStatus' }, { entity: 'role' }];
    },
    focus() {
      this.$refs.table && this.$refs.table.focus();
    },
    generateSummary({ actionTypeName }) {
      return actionTypeName;
    },
    actionsGenerated() {
      this.$refs.table.refresh();
    }
  },
  createItemFromFormData() {
    return createItemFromFormData(this);
  },
  createFormDataFromItem
};
</script>

<style scoped></style>
